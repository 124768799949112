import React from "react"
import tw from "twin.macro"
import SEO from "../components/SEO"
import { Layout } from "../components/Layout"
import { NewsletterForm } from "../components/NewsletterForm"

const OuterContainer = tw.div`w-full`
const Container = tw.div`max-w-3xl mx-auto py-4 md:py-20`
const Title = tw.h1`text-3xl font-semibold mb-4`
const Content = tw.p`text-lg`
const NewsletterFormContainer = tw.div`max-w-md mx-auto`

export default function JoinPage() {
    return (
        <Layout>
            <SEO
                title="Join Long Rock Labs"
                description="Joining Long Rock Labs is simple and easy."
                meta={[]}
            />
            <OuterContainer>
                <Container>
                    <Title>Thank you for your interest in joining Labs' Pick</Title>
                    <Content>
                        Unfortunately, we're not ready yet to accept applications to Labs' Pick.
                        Why not join our newsletter to be the first to know when applications are open?
		    </Content>
                    <NewsletterFormContainer>
                        <NewsletterForm />
                    </NewsletterFormContainer>

                </Container>
            </OuterContainer>
        </Layout>
    )
}
